<template>
	<div id="main" class="custom-full-height grid-container">
		<RefinanceHeader />

		<v-container class="maxWebsiteWidth" fluid>
			<v-row dense>
				<v-col cols="12">
					<h1 class="text-center headerMessageH1">Get The Best Deal on Solar</h1>
					<h2 class="text-center headerMessageH2">Calculate Savings. Compare Quotes.</h2>
				</v-col>
			</v-row>

			<v-row class="jumbotron custom-full-height-100" dense>
				<v-col cols="12" md="8">
					<ValidationObserver ref="formObserver" v-slot="{ }">
						<v-form id="frmLanding" @submit.prevent="onSubmit">
							<v-container fluid class="py-0 my-0">
								<v-row class="py-0 my-0" dense>
									<v-col cols="12" md="4" class="py-0 mt-0">
										<ValidationProvider name="First Name"
											rules="required|minNameLength:2|maxNameLength:50|alpha_spaces"
											v-slot="{ errors, valid }">
											<v-text-field maxlength="50" dense label="First Name" outlined id="fname"
												name="fname" ref="fname" :value="firstName" @input="setFirstName"
												:success="valid" :error-messages="errors"></v-text-field>
										</ValidationProvider>
									</v-col>

									<v-col cols="12" md="4" class="py-0 mt-0">
										<ValidationProvider name="Last Name"
											rules="required|minNameLength:2|maxNameLength:50|alpha_spaces"
											v-slot="{ errors, valid }">
											<v-text-field maxlength="50" dense label="Last Name" outlined id="lname"
												name="lname" ref="lname" :value="lastName" @input="setLastName"
												:success="valid" :error-messages="errors"> </v-text-field>
										</ValidationProvider>
									</v-col>

									<v-col cols="12" md="4" class="py-0 mt-0">
										<ValidationProvider name="Email" rules="required|email|maxNameLength:50"
											v-slot="{ errors, valid, validate }" vid="emailValidationProvider">
											<v-text-field maxlength="50" dense label="Email" type="email" outlined
												id="email" name="email" ref="email" :value="email"
												@input="validateEmail($event, validate)" :success="valid"
												:error-messages="errors">
											</v-text-field>
										</ValidationProvider>
									</v-col>

									<v-col cols="12" md="4" class="py-0 mt-0">
										<ValidationProvider name="Address"
											rules="required|minNameLength:8|maxNameLength:100" v-slot="{ errors, valid }">
											<v-text-field maxlength="50" dense label="Address" outlined id="address"
												name="address" ref="address" :value="address" @input="setAddress"
												:success="valid" :error-messages="errors">
											</v-text-field>
										</ValidationProvider>
									</v-col>

									<v-col cols="12" md="4" class="py-0 mt-0">
										<ValidationProvider name="Zip" rules="required|digits:5"
											v-slot="{ errors, valid, validate }">
											<v-text-field maxlength="5" dense label="Zip" outlined id="zip" name="zip"
												ref="zip" :value="zip" @input="validateZip($event, validate)"
												:success="valid" :error-messages="errors"> </v-text-field>
										</ValidationProvider>
									</v-col>

									<v-col cols="12" md="4" class="py-0 mt-0">
										<ValidationProvider name="State" rules="required"
											v-slot="{ errors, valid, validate }">
											<v-select dense label="State" outlined id="state" name="state" ref="state"
												:items="usStates" item-text="text" item-value="value" :value="state"
												@input="validateState($event, validate)" :success="valid"
												:error-messages="errors" @focus="$event.target.click()">
											</v-select>
										</ValidationProvider>
									</v-col>

									<v-col cols="12" md="4" class="py-0 mt-0">
										<ValidationProvider name="Phone" rules="required|phoneNumber"
											v-slot="{ errors, valid, validate }">
											<v-text-field dense label="Phone" outlined id="phone" name="phone" ref="phone"
												:value="phone" @input="validatePhone($event, validate)" :success="valid"
												:error-messages="errors" v-facade="'+1 (###) ###-####'">
											</v-text-field>
										</ValidationProvider>
									</v-col>

									<v-col cols="12" md="4" class="py-0 mt-0">
										<ValidationProvider name="Current Monthly Power Bill" rules="required"
											v-slot="{ errors, valid, validate }">
											<v-select dense label="Current Monthly Power Bill" outlined
												id="currentMonthlyPowerBill" name="currentMonthlyPowerBill"
												ref="currentMonthlyPowerBill" :items="currentMonthlyPowerBillValues"
												item-text="text" item-value="value" :value="currentMonthlyPowerBill"
												@input="validateCurrentMonthlyPowerBill($event, validate)" :success="valid"
												:error-messages="errors" @focus="$event.target.click()">
											</v-select>
										</ValidationProvider>
									</v-col>

									<v-col cols="12" md="4" class="py-0 mt-0">
										<ValidationProvider name="Do you own your home" rules="required|isHomeOwner"
											v-slot="{ errors, valid, validate }">
											<v-select dense label="Do you own your home?" outlined
												id="doYouOwnYourHomeValue" name="doYouOwnYourHomeValue"
												ref="doYouOwnYourHomeValue" :items="doYouOwnYourHomeValues" item-text="name"
												item-value="value" :value="doYouOwnYourHome"
												@input="validateDoYouOwnYourHome($event, validate)" :success="valid"
												:error-messages="errors" @focus="$event.target.click()">
											</v-select>
										</ValidationProvider>
									</v-col>

									<v-col cols="12" md="4" class="py-0 mt-0 ">
										<ValidationProvider name="Who is your electric provider" rules="required"
											v-slot="{ errors, valid, validate }">

											<v-select dense label="Who is your electric provider?" outlined
												id="electricProvider" name="electricProvider" ref="electricProvider"
												:items="tempElectricProviders" item-text="name" item-value="name"
												:value="doYouOwnYourHome"
												@input="validateWhoIsYourElectricProvider($event, validate)"
												:success="valid" :error-messages="errors" @focus="$event.target.click()">
											</v-select>
										</ValidationProvider>
									</v-col>

									<v-col cols="12" md="4" class="py-0 mt-0">
										<ValidationProvider name="How much roof shade do you have" rules="required"
											v-slot="{ errors, valid, validate }">
											<v-select dense label="How much roof shade do you have?" outlined
												id="roofShadeValue" name="roofShadeValue" ref="roofShadeValue"
												:items="roofShadeValues" item-text="name" item-value="value"
												:value="roofShade" @input="validateRoofShade($event, validate)"
												:success="valid" :error-messages="errors" @focus="$event.target.click()">
											</v-select>
										</ValidationProvider>
									</v-col>
								</v-row>

								<v-row dense class="py-0 my-0 centerElement">
									<v-col cols="12" class="py-0 my-0" align-self="center">
										<v-btn color="#009800" x-large @click="onSubmit" ref="btnProcess" id="btnProcess"
											name="btnProcess">
											<span class="getYourQuoteBtn"> VIEW RESULTS </span>
										</v-btn>
									</v-col>
								</v-row>

								<v-row dense class="py-0 my-0 centerElement" justify="center">
									<v-col cols="12" class="py-0 my-0" align-self="center">
										<p class="pDisclaimer">
											By clicking on the "View Results" button above, you consent,
											acknowledge, and agree to our Terms of Use Agreement, Privacy Policy
											and providing express "written" consent to share your information
											with up to 3 “Go Solar Energy Network Partners" listed here <a
												@click="dialog = true">NETWORK PARTNERS</a>
											authorized third party on their behalf to call you (including by automated
											dialing systems, text and pre-recorded voice messages) via telephone, mobile
											device (including SMS and MMS - charges may apply) and/or email, even if your
											telephone number is currently listed on any internal, corporate, state, federal
											or national Do-Not-Call (DNC) list. Consent is not required in order to purchase
											goods and services from the lenders that reach out to you.
										</p>
									</v-col>
									<v-col cols="12" class="py-0 my-0 " align-self="center">
										<p class="pDisclaimer text-center">
											100% Free Quotes - No Credit Check - No SSN Required
										</p>
									</v-col>
								</v-row>

								<v-row justify="center">
									<v-dialog v-model="dialog" persistent scrollable max-width="80vw">
										<v-card>
											<v-card-title class="text-h5"> GoSolarQuotes - Network Partners </v-card-title>
											<v-card-text>
												<ol class="list-unstyled">
													<li v-for="(partner, index) in networkPartners" :key="index">
														<v-icon color="green darken-2">mdi-check</v-icon>
														{{ partner }}
													</li>
												</ol>
											</v-card-text>
											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn color="green darken-1" text @click="dialog = false"> Close </v-btn>
											</v-card-actions>
										</v-card>
									</v-dialog>
								</v-row>
							</v-container>

							<input id="leadid_token" name="universal_leadid" type="hidden" value="" />
							<input type="hidden" name="ipaddress" id="ipaddress"
								value="<?PHP echo $_SERVER['REMOTE_ADDR']; ?>" />

							<ActiveProspectTrustedForm ref="tfCode" />
						</v-form>
					</ValidationObserver>
				</v-col>

				<v-col cols="12" md="4" class="d-none d-md-flex">
					<v-container fluid>
						<v-row>
							<v-col>
								<h3>LOW RATES ON SOLAR PANELS</h3>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<ul class="list-unstyled">
									<li><v-icon color="green darken-2">mdi-check-bold</v-icon> Lower Your Electric Bill</li>
									<li><v-icon color="green darken-2">mdi-check-bold</v-icon> Financing Available</li>
									<li><v-icon color="green darken-2">mdi-check-bold</v-icon> Free No Obligation Quote</li>
									<li><v-icon color="green darken-2">mdi-check-bold</v-icon> Easy Approval</li>
								</ul>
							</v-col>
						</v-row>
					</v-container>
				</v-col>
			</v-row>
		</v-container>

		<RefinanceFooter />

		<!-- <JornayaEmbedJSCode /> -->
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import RefinanceHeader from "../components/RefinanceHeader";
import RefinanceFooter from "../components/RefinanceFooter";
import JornayaEmbedJSCode from "../components/Jornaya";
import ActiveProspectTrustedForm from "../components/TrustedForm.vue"

import { InputFacade, facade, filter } from "vue-input-facade";

import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import axios from "axios";

export default {
	name: "Home",

	components: {
		RefinanceHeader,
		RefinanceFooter,
		ValidationProvider,
		ValidationObserver,
		JornayaEmbedJSCode,
		InputFacade,
		ActiveProspectTrustedForm,
	},

	directives: { facade },
	filters: { facade: filter },

	data() {
		return {
			dialog: false,
			isFormDataValid: false,
			tempElectricProviders: null,
		};
	},

	methods: {
		onSubmit() {
			this.$refs.formObserver.validate().then((success) => {
				if (!success) {
					this.isFormDataValid = false;
					return;
				}

				this.isFormDataValid = true;

				//reset values or go next page
				//Go To Processing Info
				this.setFormFilled(true);

				this.$refs.tfCode.removeTF();
				this.$router.push({ name: "ProcessingInfo" });
			});
		},

		...mapActions([
			"setFirstName",
			"setLastName",
			"setEmail",
			"setAddress",
			"setZip",
			"setCity",
			"setState",
			"setPhone",
			"setCellPhone",
			// "setPropertyValue",
			"setMortgageBalance",
			"setCreditRating",
			"setVALoan",
			"setInterestRate",
			"setLatePaymentPast12Months",
			"setAnyBankruptcyPast3Years",
			"setFormFilled",
			"setCurrentMonthlyPowerBillValue",
			"setDoYouOwnYourHomeValue",
			"setRoofShade",
			"setElectricProvider",
		]),

		async validateEmail(value, validate) {
			const valid = await validate(value);
			this.setEmail(value);
			if (valid.valid === true) {
				let emailParts = value.split(".");
				let emailExtension = emailParts[emailParts.length - 1];
				let ValidExtensionsForMove = ["com", "net", "org"];
				if (ValidExtensionsForMove.includes(emailExtension)) {
					this.$refs.address.focus();
				}
			}
		},

		async validateZip(value, validate) {
			const valid = await validate(value);
			this.setZip(value);
			if (valid.valid === true) {
				this.$refs.state.focus();
			}
		},

		async validateState(value, validate) {
			const valid = await validate(value);
			this.setState(value);
			if (valid.valid === true) {
				this.$refs.phone.focus();
			}
		},

		async validatePhone(value, validate) {
			const valid = await validate(value);
			this.setPhone(value);
			if (valid.valid === true) {
				this.$refs.currentMonthlyPowerBill.focus();
			}
		},

		async validateCurrentMonthlyPowerBill(value, validate) {
			const valid = await validate(value);
			this.setCurrentMonthlyPowerBillValue(value);
			if (valid.valid === true) {
				this.$refs.doYouOwnYourHomeValue.focus();
			}
		},

		async validateDoYouOwnYourHome(value, validate) {
			const valid = await validate(value);
			this.setDoYouOwnYourHomeValue(value);
			if (valid.valid === true) {
				this.$refs.electricProvider.focus();
			}
		},

		async validateWhoIsYourElectricProvider(value, validate) {
			const valid = await validate(value);
			this.setElectricProvider(value);
			if (valid.valid === true) {
				this.$refs.roofShadeValue.focus();
			}
		},

		async validateRoofShade(value, validate) {
			const valid = await validate(value);
			this.setRoofShade(value);
			if (valid.valid === true) {
				this.$refs.btnProcess.focus();
			}
		},

		async getElectricProviderByZip() {
			try {
				if (this.zip) {
					let tempZip = this.zip * 1;
					let ELECTRIC_URL = process.env.VUE_APP_ELECTRIC_URL;
					console.log('ELECTRIC_URL', ELECTRIC_URL);
					let axiosAnswer = await axios.get(ELECTRIC_URL + "?ZipCode=" + tempZip);
					let tempElectricProviders = axiosAnswer.data.data;
					console.log('tempElectricProviders', tempElectricProviders);
					this.tempElectricProviders = tempElectricProviders;
					return;
				}

				this.tempElectricProviders = null;

			}
			catch (e) {
				this.tempElectricProviders = null;
				console.error('getElectricProviderByZip', e);
			}
		},
	},

	computed: {
		...mapState([
			"firstName",
			"lastName",
			"email",
			"address",
			"zip",
			"city",
			"state",
			"phone",
			"cellPhone",
			"networkPartners",
			"usStates",
			"formFilled",
			"currentMonthlyPowerBillValues",
			"currentMonthlyPowerBill",
			"doYouOwnYourHomeValues",
			"doYouOwnYourHome",
			"roofShade",
			"roofShadeValues",
			"electricProvider",
		]),

		...mapGetters([]),
	},

	watch: {
		zip: function (val) {
			if (val && val.length >= 5) {
				this.getElectricProviderByZip();
				return;
			}

			this.setElectricProvider(null);
			this.tempElectricProviders = null;
		},
	},

	mounted() {
		document.title = "GoSolarQuotes - Home";
		if (this.formFilled === true) {
			this.setFormFilled(false);
		}
	},
};
</script>

<style></style>
