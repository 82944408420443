import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({

    
    theme: {
        themes: {
            light: {
                primary: "#009800", // #E53935
                secondary: "#89c709", // #FFCDD2
            },
        },
    },


});
