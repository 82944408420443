<template>
    <div>
        <input id="LeadiDscript" name="LeadiDscript" type="hidden" value="" />
        <noscript><img
                src='//create.leadid.com/noscript.gif?lac=9438757a-b33e-9fb8-7b3f-ce6958bfc994&lck=2b1094f1-a56c-1999-f5fe-b470a930c74e&snippet_version=2' /></noscript>
    </div>
</template>

<script>
export default {
    name: 'JornayaEmbedJSCode',

    data() {
        return {

        }
    },

    mounted() {
        (function () {
            var s = document.createElement('script');
            s.id = 'LeadiDscript_campaign';
            s.type = 'text/javascript';
            s.async = true;
            s.src = '//create.lidstatic.com/campaign/2b1094f1-a56c-1999-f5fe-b470a930c74e.js?snippet_version=2';
            var LeadiDscript = document.getElementById('LeadiDscript');
            LeadiDscript.parentNode.insertBefore(s, LeadiDscript);
        })();
    }

}
</script>

<style></style>