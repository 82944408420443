<template>
    <v-container>
        <v-row>
            <v-col cols="12">

                <h1>
                    Consumer Privacy Disclosure
                </h1>

                <br>

                <p>
                    This site is owned and operated by GoSolarQuotes Thank you for visiting. Our goal is to provide you the
                    services you want while keeping your information confidential. This privacy policy describes how we use
                    your data across all our platforms, products, and services. This policy applies to GoSolarQuotes and all
                    affiliates where it is published. Our <a href="#">product privacy notice</a> explores this topic
                    further.
                </p>
                <p>
                    If you are a California resident, please see the section on the California Consumer Privacy Act at the
                    end of this document.
                </p>
                <p>
                    GoSolarQuotes is a website created in the United States to provide users with services they want in the
                    U.S. We are governed by U.S. law and make no representation that we are governed by, or controlled in
                    accordance with the laws of, any other country.
                </p>
                <p>
                    You must be 18 years old to use our site. We do not knowingly collect, use, or share personal
                    information about minors. If you are under 18 years old, you may only use our site and services in
                    cooperation with a parent or guardian. See our <a @click="openTermsAndConditions">terms</a> of service
                    for more information.
                </p>
                <p>
                    GoSolarQuotes may occasionally update this privacy disclosure, at which time we will post the change
                    here. If the change materially affects the use of your personal information, all users will be notified
                    and asked to opt in. If you do not like the change, please decline the change when notified and
                    discontinue using our site.
                </p>

                <h2>
                    The Information We Collect
                </h2>
                <p>
                    Like other websites, GoSolarQuotes records certain types of information every time a user visits. We
                    collect information that can identify a user. This includes name, address, telephone number, Social
                    Security Number, and Internet Protocol (IP) address.
                </p>
                <p>
                    GoSolarQuotes collects financial information to complete the application process, both directly on our
                    site and through our financial partners. Such information includes credit history, credit scores,
                    transaction history, and credit products and payments.
                </p>
                <p>
                    GoSolarQuotes may collect demographic information about a user, such as age and ZIP Code. We may also
                    collect data about online activity or receive, for tracking purposes, information from your internet
                    service provider or other third party. We may add this information to your existing account.
                </p>

                <h2>
                    How We Collect information
                </h2>
                <p>
                    <strong>From you—</strong>From the user/consumer we collect the information the user provides in
                    connection with obtaining financial products and services, such as mortgage loans (name, telephone
                    number, self-assessed property value, and Social Security Number). We collect the contact information
                    you provided for receiving messages, such as your email address. We collect the information you provide
                    when you enter promotions or sweepstakes offered by our site, and when you fill out surveys or other
                    forms hosted on our site or the site of a business partner.
                </p>
                <p>
                    <strong>From third parties—</strong>We collect information from credit bureaus, lead generators, and any
                    partner that might have access to your financial data. The data we collect from third parties sometimes
                    includes demographic information.
                </p>
                <p>
                    <strong>Cookies and IP addresses—</strong>We use cookies to personalize and streamline your experience
                    on our site. Cookies are small text files stored on your machine and read by our site when you visit. If
                    you have ever been greeted by name when you return to a site, this is the effect of a cookie that
                    “remembered” your name. We sometimes use cookies from advertising companies to track and personalize the
                    offers that appear on our site. These ad companies may use cookies and action tags to measure ad
                    effectiveness. Any information that third parties derive from cookies and action tags is anonymous. We
                    also log Internet Protocol (IP) addresses, which are unique identifiers for every computer connected to
                    the internet. To opt out of cookies: See your browser’s settings. Most browsers accept cookies by
                    default but offer you ways to limit or refuse them.
                </p>

                <h2>
                    How We Use Information
                </h2>
                <p>
                    GoSolarQuotes uses information to provide personalized service to customers, to communicate with
                    customers about services that may be of interest to them, and to improve overall company performance
                    (customer service, user experience, business management).
                </p>

                <h2>
                    Information Sharing and Disclosure
                </h2>
                <p>
                    GoSolarQuotes does not share your personal information with other people or unaffiliated businesses
                    except to provide products and services you have requested or under the following circumstances.
                </p>
                <p>
                    <strong>Loan products or service requests—</strong>When you request a loan product or service through
                    our website, we will share personal information contained in the request (such as name, address,
                    telephone number, self-assessed property value, and Social Security Number) with lenders in our network
                    solely to fulfil your request. All lenders in our network, which includes banks and mortgage brokers,
                    have entered into marketing agreements with us that comply with federal and state privacy regulations.
                    The lenders may contact you by phone, email, or post to fulfill your request even if you registered for
                    the National Do Not Call List administered by the Federal Trade Commission or any equivalent list
                    managed by a state or a company. If a lender contacts you by telephone about your request and you do not
                    want the lender to call again, say so directly in the phone call. Please note that lenders may retain
                    your information whether you eventually choose to do business with them or not.
                </p>
                <p>
                    <strong>Vendors—</strong>We sometimes share information with companies that host services for us, such
                    as marketing distribution services. These companies have access only to the personal information they
                    need to do their job and cannot share it with others or use it for any other purpose, as is outlined in
                    the confidentiality agreements and other legal documents they signed with GoSolarQuotes.
                </p>
                <p>
                    <strong>As allowed by law—</strong>We may be compelled to share your personal information with law
                    enforcement or government agencies in response to subpoenas, court orders, government audits, or the
                    like. To investigate or prevent fraud or illegal activities or threats, we believe in sharing
                    information.
                </p>

                <h2>
                    Email Communications and Marketing
                </h2>
                <p>
                    You will not receive email messages from us unless you register to receive them. From third parties you
                    may receive email messages that advertise GoSolarQuotes's services, but we have set strict requirements
                    about such communications; namely, that they must not be part of a “spam” email campaign and they must
                    only be sent to people who gave permission to receive third-party email messages. Should you ever wish
                    to rescind this permission, look for an “unsubscribe” link in the body of the message. All messages
                    should contain a way to unsubscribe or opt out of future mailings.
                </p>

                <h2>
                    Confidentiality and Security
                </h2>
                <p>
                    Keeping your personal information secure is important to us. We take many steps to guard your data,
                    which you can read about on our <a @click="openSecurityPolicy">security page</a>.
                </p>

                <h2>
                    Need to Contact Us?
                </h2>
                <p>
                    <strong>To update your personal information—</strong>If your information changes or you no longer wish
                    to use our service, you can update, delete, or deactivate your account by writing our customer support
                    department at <a href="mailto:nick@quotelogic.co">contact@gosolarquotes.co</a>.
                </p>
                <p>
                    <strong>For questions, comments, or concerns—</strong>If you want to contact us about this privacy
                    policy or our services, please write to <a
                        href="mailto:nick@quotelogic.co">contact@gosolarquotes.co</a>.
                </p>

                <h2>
                    California Residents
                </h2>
                <p>
                    This notice is required to be published by the California Consumer Privacy Act of 2018 (CCPA) to advise
                    California residents how we collect, use, and share their personal information. The interactions between
                    California residents and GoSolarQuotes are limited to visiting our website, subscribing to our mailing
                    list, creating an account with us that does not include financial products/services, and applying for a
                    job at GoSolarQuotes.
                </p>
                <p>
                    The CCPA states that personal information is anything that can identify a California resident.
                    Nonetheless, this does not apply to specific information we collect, utilize, or disclose for consumers
                    that start or finish the process of applying for our financial services. This is a result of certain
                    federal privacy laws, like the Gramm-Leach-Bliley Act (GLBA) and the California Financial Information
                    Privacy Act (FIPA). This also applies to representatives of businesses that are interested in our
                    products or want to offer their services to us. Our <a href="#">product privacy notice</a> explores this
                    topic further.
                </p>
                <p>
                    Privacy practices—We do not sell personal information. As stated, we use cookies and other digital
                    tracking mechanisms to help us tailor our site and services to our customers’ needs and to ensure that
                    we are targeting the right markets. Please see the cookie policy above to read more about it and learn
                    how you can adjust your browser’s settings to opt out. We provide the following table to make you aware
                    of when and how we collect your information and how it might be used.
                </p>

                <p>
                <table class="MuiTable-root" aria-label="simple table">
                    <tbody class="MuiTableBody-root">
                        <tr class="MuiTableRow-root">
                            <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">If you:</td>
                            <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                                <ul>
                                    <li>Subscribe to email</li>
                                    <li>Interact with blogs</li>
                                    <li>Create an Account</li>
                                </ul>
                            </td>
                            <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                                <ul>
                                    <li>Visit Website </li>
                                    <li>Receive/Respond to our emails</li>
                                </ul>
                            </td>
                            <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                                <ul>
                                    <li>Apply to a job at our company</li>
                                </ul>
                            </td>
                        </tr>
                        <tr class="MuiTableRow-root">
                            <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">This personal information
                                is collected:</td>
                            <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                                <ul>
                                    <li>Name/Alias</li>
                                    <li>Email</li>
                                </ul>
                            </td>
                            <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                                <ul>
                                    <li>Device Data</li>
                                    <li>Online data activity </li>
                                    <li>Information derived from device data and online activity data </li>
                                </ul>
                            </td>
                            <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                                <ul>
                                    <li>Contact Information</li>
                                    <li>Professional Credentials</li>
                                    <li>Resume info</li>
                                    <li>Education/work history</li>
                                    <li>Diversity info voluntarily provided</li>
                                    <li>Disability info if necessary</li>
                                </ul>
                            </td>
                        </tr>
                        <tr class="MuiTableRow-root">
                            <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">CCPA Defined Categories:
                            </td>
                            <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                                <ul>
                                    <li>Identifiers</li>
                                    <li>Online identifiers</li>
                                </ul>
                            </td>
                            <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                                <ul>
                                    <li>identifiers</li>
                                    <li>Inferences</li>
                                    <li>Internet or network information</li>
                                    <li>Online identifiers</li>
                                </ul>
                            </td>
                            <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                                <ul>
                                    <li>Identifiers</li>
                                    <li>Online identifiers</li>
                                    <li>Educational information</li>
                                    <li>Professional or employment information</li>
                                    <li>protected</li>
                                </ul>
                            </td>
                        </tr>
                        <tr class="MuiTableRow-root">
                            <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">The source for obtaining
                                personal information:</td>
                            <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                                <ul>
                                    <li>From the data you provide </li>
                                </ul>
                            </td>
                            <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                                <ul>
                                    <li>Automatic collection</li>
                                </ul>
                            </td>
                            <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                                <ul>
                                    <li>You</li>
                                    <li>References</li>
                                    <li>Background check/drug testing</li>
                                    <li>Employment agencies/recruiters</li>
                                </ul>
                            </td>
                        </tr>
                        <tr class="MuiTableRow-root">
                            <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">How your information may
                                be used:</td>
                            <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                                <ul>
                                    <li>Operations</li>
                                    <li>Communicat</li>
                                    <li>R&amp;D</li>
                                <li>Marketing</li>
                            </ul>
                        </td>
                        <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                            <ul>
                                <li>Operations</li>
                                <li>Communications</li>
                                <li>Marketing</li>
                                <li>Advertising</li>
                                <li>R&amp;D</li>
                            </ul>
                        </td>
                        <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                            <ul>
                                <li>Recruitment Activities</li>
                                <li>Process employment apps</li>
                                <li>Recruitment stats</li>
                            </ul>
                        </td>
                    </tr>
                    <tr class="MuiTableRow-root">
                        <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">Who we share with:</td>
                        <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                            <ul>
                                <li>Service providers</li>
                                <li>Made public at your direction</li>
                            </ul>
                        </td>
                        <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                            <ul>
                                <li>Collected directly by or shared with our service providers </li>
                                <li>Collected by advertising partners</li>
                            </ul>
                        </td>
                        <td class="MuiTableCell-root MuiTableCell-body MuiTableCell-alignCenter">
                            <ul>
                                <li>Shared with service providers</li>
                                <li>Collected directly by or shared with background check and drug testing companies
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            </p>

            <p>
                The CCPA permits California residents the right to inquire about how your information was accessed,
                used, or shared in the past 12 months; to receive a copy of the information we hold about you; and to
                request that we delete the information we hold about you. To exercise any of these rights, please write
                to <a href="mailto:nick@quotelogic.co">contact@gosolarquotes.co</a>. For us to comply, we may need you
                to verify your identity. If you have authorized an agent to make your request(s), we will need to take
                further security actions to protect all parties.
            </p>

            <h2>
                Advertising Disclosures
            </h2>
            <p>
                GoSolarQuotes is not a banker or lender (Service Provider). Leading Service Providers participate in our
                matching engine who may have loan products available matching the criteria you submit in this interest
                profile. The information collected is not an application for credit or a mortgage loan, nor is it used
                to pre-qualify you with any Service Provider. There is no cost to submit a loan request, get matched
                with lenders and receive loan offers. You may review the loan offers and talk to the lenders at no cost.
                Of course, the lender you choose may require a fee to continue processing your loan request, but until
                you agree to pay the lender a fee, you may stop at no cost. GoSolarQuotes does not charge you, the
                consumer, a fee for its services. Who pays our bills? The lender. Of course, you will be responsible for
                paying any loan processing, closing costs or other fees to the lender with whom you close.
            </p>

        </v-col>
    </v-row>
</v-container></template>

<script>
export default {
    name: 'Disclosure',

    methods: {
        openTermsAndConditions() {
            this.$emit('openTermsAndConditions')
        },
        openSecurityPolicy() {
            this.$emit('openSecurityPolicy')
        },
    }
}
</script>

<style></style>