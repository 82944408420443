<template>
    <footer>
        <div class="links my-0 pt-0">

            <v-container class="maxWebsiteWidth" fluid>
                <v-row class="mx-auto">
                    <v-col cols="12" md="3">
                        <h6 class="clearfix">Homeowners Trust Us</h6>
                        <p class="goal">
                            We created GO Solar is an online platform where solar installers compete for your business.
                            Whether you're looking for solar panels or battery back up's GO Solar is here to help you secure
                            the lowest rates possible.
                        </p>
                        <div class="logo-box">
                            <div class="logo">
                                <img src="/img/logo.svg" alt="GoSolarQuotes.co">
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="3">
                        <h6 class="clearfix">Service</h6>
                        <ul class="goal">
                            <li><a href="#">Solar Panel Quotes</a></li>
                            <li><a href="#">Solar Panel Installation</a></li>
                            <li><a href="#">Solar Panel Appointments</a></li>
                            <li><a href="#">Solar Panel Experts</a></li>
                        </ul>
                    </v-col>
                    <v-col cols="12" md="3">
                        <h6 class="clearfix">Resources</h6>
                        <ul class="goal">
                            <li><a href="#">Solar Tips</a></li>
                            <li><a href="#">Solar Money Tips</a></li>
                            <li><a href="#">Solar Credit Tips</a></li>
                            <li><a href="#">Solar Guide</a></li>
                        </ul>
                    </v-col>
                    <v-col cols="12" md="3">
                        <h6 class="clearfix">Company</h6>
                        <ul class="goal">
                            <li><a @click="privacyDialog = true">Privacy</a></li>
                            <li><a @click="termsDialog = true">Terms &amp;
                                    Conditions</a></li>
                            <li><a @click="disclosureDialog = true">Disclosure</a></li>
                            <li><a @click="securityDialog = true">Security Privacy</a></li>
                        </ul>
                    </v-col>

                </v-row>
            </v-container>

            <v-dialog v-model="privacyDialog" persistent max-width="80vw" scrollable>
                <v-card>
                    <v-card-title class="text-h5">

                    </v-card-title>
                    <v-card-text>
                        <Privacy @openTermsAndConditions='openTermsAndConditionsFromPrivacy' />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="privacyDialog = false">
                            Close
                        </v-btn>

                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="termsDialog" persistent max-width="80vw" scrollable>
                <v-card>
                    <v-card-title class="text-h5">

                    </v-card-title>
                    <v-card-text>
                        <TermsAndConditions @openPrivacyPolicy='openPrivacyPolicyFromTermsAndConditions' />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="termsDialog = false">
                            Close
                        </v-btn>

                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="disclosureDialog" persistent max-width="80vw" scrollable>
                <v-card>
                    <v-card-title class="text-h5">

                    </v-card-title>
                    <v-card-text>
                        <Disclosure @openTermsAndConditions='openTermsAndConditionsFromDisclosure'
                            @openSecurityPolicy='openSecurityPolicyFromDisclosure' />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="disclosureDialog = false">
                            Close
                        </v-btn>

                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="securityDialog" persistent max-width="80vw" scrollable>
                <v-card>
                    <v-card-title class="text-h5">

                    </v-card-title>
                    <v-card-text>
                        <SecurityPrivacy />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="securityDialog = false">
                            Close
                        </v-btn>

                    </v-card-actions>
                </v-card>
            </v-dialog>

        </div>
    </footer>
</template>

<script>

import Privacy from './Footer/Privacy'
import TermsAndConditions from './Footer/TermsAndConditions'
import Disclosure from './Footer/Disclosure.vue'
import SecurityPrivacy from './Footer/SecurityPrivacy.vue'

export default {
    name: 'RefinanceFooter',

    components: {
        Privacy,
        TermsAndConditions,
        Disclosure,
        SecurityPrivacy,
    },

    data() {
        return {
            privacyDialog: false,
            termsDialog: false,
            disclosureDialog: false,
            securityDialog: false,


        }
    },

    methods: {
        openTermsAndConditionsFromPrivacy() {
            this.privacyDialog = false
            this.termsDialog = true
        },

        openPrivacyPolicyFromTermsAndConditions() {
            this.termsDialog = false
            this.privacyDialog = true
        },

        openTermsAndConditionsFromDisclosure() {
            this.disclosureDialog = false
            this.termsDialog = true
        },

        openSecurityPolicyFromDisclosure() {
            this.disclosureDialog = false
            this.securityDialog = true
        },
    }

}
</script>

<style></style>