<template>
    <div>
        <input id="xxTrustedFormToken" name="xxTrustedFormToken" type="hidden" value="" />
        <input id="xxTrustedFormCertUrl" name="xxTrustedFormCertUrl" type="hidden" value="" />
        <input id="xxTrustedFormPingUrl" name="xxTrustedFormPingUrl" type="hidden" value="" />
        <noscript>
            <img src="https://api.trustedform.com/ns.gif" />
        </noscript>
    </div>
</template>

<script>
export default {
    name: 'ActiveProspectTrustedForm',

    data() {
        return {
            tf: null,
            s: null,
            newNode: null,
        }
    },
    methods: {
        addTF() {
            try {
                this.tf = document.createElement('script');
                this.tf.type = 'text/javascript';
                this.tf.async = true;
                this.tf.src = ("https:" == document.location.protocol ? 'https' : 'http') + "://api.trustedform.com/trustedform.js?field=xxTrustedFormCertUrl&ping_field=xxTrustedFormPingUrl&l=" + new Date().getTime() + Math.random();
                this.s = document.getElementsByTagName('script')[0];
                this.newNode = this.s.parentNode.insertBefore(this.tf, this.s);

                console.log('addTF - TF added.')
            } catch (e) {
                console.error('ActiveProspectTrustedForm - Error addTF', e);
            }
        },
        removeTF() {
            try {
                trustedFormStopRecording();
                this.s.parentNode.removeChild(tf);
                console.log('removeTF - TF removed.')

            } catch (e) {
                console.error('ActiveProspectTrustedForm - Error removeTF', e);
            }
        }
    },

    mounted() {
        try {
            this.addTF();
        } catch (e) {
            console.error('ActiveProspectTrustedForm - Error mounted', e);
        }
    },
    unmounted() {
        try {
            this.removeTF();
        } catch (e) {
            console.error('ActiveProspectTrustedForm - Error unmounted', e);
        }

    },

}
</script>

<style></style>