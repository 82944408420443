import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ProcessingInfo from '../views/ProcessingInfo.vue'
import ThankYou from '../views/ThankYou.vue'
import NotFoundComponent from '../views/NotFoundComponent.vue'

Vue.use(VueRouter)

const TITLE_PREFIX = 'GoRefinance - '
const DEFAULT_TITLE = 'GoRefinance'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: TITLE_PREFIX + 'Home'
    }
  },
  {
    path: '/processinginfo',
    name: 'ProcessingInfo',
    component: ProcessingInfo,
    meta: {
      title: TITLE_PREFIX + 'Processing Your Quote'
    }
  },
  {
    path: '/thankyou',
    name: 'ThankYou',
    component: ThankYou,
    meta: {
      title: TITLE_PREFIX + 'Thank You'
    }
  },
  /*
  { 
    path: '/:catchAll(.*)', 
    component: NotFoundComponent,
    name: 'NotFound',
    meta: {
      title: TITLE_PREFIX + '404'
    }
  },
  */
  { 
    path: '/404', 
    name: 'NotFound',
    component: NotFoundComponent,
    meta: {
      title: TITLE_PREFIX + '404'
    }
  },
  {
    path: '*',
    redirect: {name: 'NotFound'}
  }
]

const router = new VueRouter({
  mode: 'history', 
  base: '',
  routes
})

export default router
