<template>
    <div>
        <RefinanceHeader />
        <v-container fluid>
            <v-row>
                <v-col cols="12" class="maxWebsiteWidth text-center">
                    <h1>404 Page Not Found</h1>
                    <h2>
                        Go to <router-link :to="{ name: 'Home'}">Home</router-link>
                    </h2>
                </v-col>
            </v-row>
        </v-container>
        <RerfinanceFooter />
    </div>
</template>

<script>
    import RefinanceHeader from '../components/RefinanceHeader'
    import RerfinanceFooter from '../components/RefinanceFooter'

    export default {
        name: 'NotFoundComponent',
        
        components: {
            RefinanceHeader,
            RerfinanceFooter,
            
        },
        methods: {
            
        },

        mounted() {
            this.$router.push({name: 'Home'})
        }

    }
</script>

<style>

</style>