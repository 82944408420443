<template>
  <div class="hideScrollBar">
    <v-container fill-height class="loader-box my-4 ">
      <v-row align-content="center">
        <v-col align-self="center">
          <p class="customParagraph my-5">
            <img src="/img/logo.svg" alt="GoSolarQuotes.co" class="customIcon">
          </p>
          <p class="customParagraph">
            <v-progress-linear indeterminate color="#89c709" height="15" rounded background-color="#46505f">
            </v-progress-linear>
          </p>
          <p class="customParagraph my-5">
            Applying Discounts
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {
  name: 'ProcessingInfo',

  methods: {
    waitAndDispatch() {
      setTimeout(
        () => this.goTo(),
        5000
      )
    },

    goTo() {
      this.$router.push({ name: 'ThankYou' })
    },

    ...mapActions([
      'setFormFilled',
      'resetValues',
    ]),
  },

  computed: {

    ...mapState([
      'formFilled',
    ]),
  },

  mounted() {
    if (this.formFilled === true) {
      this.waitAndDispatch()
    }
    else {
      this.resetValues()
      this.$router.replace({ name: 'Home' })
    }

  }

}
</script>

<style>
.hideScrollBar {
  height: 95vh;
  overflow: hidden;
  scrollbar-width: none;
}

.loader-box {
  max-width: 600px;
}

.customParagraph {
  text-align: center;
  font-size: 20px
}

.customIcon {
  background-size: contain;
  max-width: 50%;
  max-height: 20vh;
}
</style>