<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <h1>
                    Security Policy
                </h1>

                <br>

                <h2>
                    Security
                </h2>

                <p>
                    Here at GosolarQuotes.co, the security of your personal information is a top priority. While no online
                    dealing can be guaranteed to be 100% secure, you can rest easy knowing that GosolarQuotes has taken
                    every available precaution.that is why we have built our site with data security as a priority. The
                    information shared between GosolarQuotes, banks, lenders, loan officers, loan brokers, real estate
                    professionals, and affiliates is encrypted using the Secure Socket Layer (SSL) protocol.
                </p>

                <h2>
                    Web Pages and Encryption
                </h2>
                <p>
                    Not only do we utilize SSL to help prevent unauthorized parties from accessing your information, but
                    also public key cryptography algorithms. What does all of that mean? That we’ve gone to great lengths to
                    ensure we are the only ones who can decode your sensitive information. For optimal security, we
                    recommend the following internet browsers when using our site: Chrome, Edge, Firefox, Internet Explorer,
                    Opera or Safari.
                </p>
                <p>
                    GosolarQuotes.co only accepts ssl encrypted connections. Any user that tries to access GosolarQuotes.co
                    using an unsecured url will be redirected to a secure url automatically. You can verify this before
                    sending your information, check for this symbol <img class="img15"
                        :src="require('../../assets/img/lock.svg')" alt=""> or check to make sure that the web url starts
                    with ‘https’, as it confirms that the data you send will be encrypted.
                </p>

                <h2>
                    Firewall Protection
                </h2>
                <p>
                    We have installed next-generation firewalls to protect all parties, resources, and data from internal
                    and external threats to our network.
                </p>

            </v-col>
        </v-row>
    </v-container></template>

<script>
export default {
    name: 'SecurityPrivacy',

}
</script>

<style></style>