<template>
    <div>
        <RefinanceHeader />
        
        <v-container fluid class="maxWebsiteWidth">

            <v-row class="my-0 py-0">
                <v-col cols="12" class="my-0 py-0">
                    <v-container class="my-0 py-0">
                        <v-row class="c-points">

                            <v-col cols="6" sm="3">
                                <div class="point">
                                    <img :src="require('../assets/img/call.svg')" alt="" class="icon">
                                    <h1>A Lender Will Call Right Away</h1>
                                    <h2>Be ready to take call - This Lender will be calling with their best offer.</h2>
                                </div>
                            </v-col>

                            <v-col cols="6" sm="3">
                                <div class="point">
                                    <img :src="require('../assets/img/savings.svg')" alt="" class="icon">
                                    <h1>Savings Over $4,000 Yearly</h1>
                                    <h2>Please talk to this lender, savings may surprise you.</h2>
                                </div>
                            </v-col>

                            <v-col cols="6" sm="3">
                                <div class="point">
                                    <img :src="require('../assets/img/matched.svg')" alt="" class="icon">
                                    <h1>We won't waste your time</h1>
                                    <h2>This lender is likely to lend to you based on your criteria.</h2>
                                </div>
                            </v-col>

                            <v-col cols="6" sm="3">
                                <div class="point">
                                    <img :src="require('../assets/img/lenders.svg')" alt="" class="icon">
                                    <h1>Let the lenders and agents do the work</h1>
                                    <h2>Give us a couple of minutes, they'll help you save.</h2>
                                </div>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-col>
            </v-row>


            <v-row class="my-0 py-0">
                <v-container class="my-0 py-0">
                    <v-row>
                        <v-col class="my-0 py-0">
                            <div class="punch">
                                <h1>Exclusive Single Lender Match!</h1>
                                <h2>After a thorough analysis of our lender network, <br> our system has matched you with the single best lender that can offer you a super low rate. </h2>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="my-0 py-0">
                            <div class="punch">
                                <h1>Expect a call shortly!</h1>
                            </div>
                        </v-col>
                    </v-row>

                </v-container>
            </v-row>

        </v-container>
        <RerfinanceFooter class="mt-10" />
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'

import RefinanceHeader from '../components/RefinanceHeader'
import RerfinanceFooter from '../components/RefinanceFooter'

export default {
    name: 'ThankYou',

    components: {
        RefinanceHeader,
        RerfinanceFooter,
    },
    
    data() {
        return {
            
        }
    },

    methods: {
        ...mapActions([
            'setFormFilled',
            'resetValues',
        ]),
    },

    computed: {
        ...mapState([
            'formFilled',
        ]),
    },

    mounted() {
        
        if(this.formFilled === false) {
            this.resetValues()
            this.$router.replace({name: 'Home'})
        }
        
    }

}
</script>

<style>

    

</style>