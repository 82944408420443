import { extend } from "vee-validate";
import { required, email, alpha, alpha_dash, alpha_num, alpha_spaces, digits } from "vee-validate/dist/rules";

extend("required", {
	...required,
	message: (fieldName, placeholders) => {
		return `The ${fieldName} field is required.`;
	},
});

extend("email", {
	...email,
	message: (fieldName, placeholders) => {
		return `The ${fieldName} field is not a valid email.`;
	},
});

extend("alpha", {
	...alpha,
	message: (fieldName, placeholders) => {
		return `The ${fieldName} field may only contain alphabetic characters.`;
	},
});

extend("alpha_dash", {
	...alpha_dash,
	message: (fieldName, placeholders) => {
		return `The ${fieldName} field may only contain alphabetic characters, numbers, dashes or underscores.`;
	},
});

extend("alpha_num", {
	...alpha_num,
	message: (fieldName, placeholders) => {
		return `The ${fieldName} field may only contain alphabetic characters or numbers.`;
	},
});

extend("alpha_spaces", {
	...alpha_spaces,
	message: (fieldName, placeholders) => {
		return `The ${fieldName} field may only contain alphabetic characters or spaces.`;
	},
});

extend("digits", {
	...digits,
	message: (fieldName, placeholders) => {
		return `The ${fieldName} field must be numeric and exactly contain ${placeholders.length} digits`;
	},
});

/////////////////////////////////

extend("minNameLength", {
	validate(value, { length }) {
		return value.length >= length;
	},
	params: ["length"],
	message: (fieldName, placeholders) => {
		return `The ${fieldName} field must have at least ${placeholders.length} characters.`;
	},
});

extend("maxNameLength", {
	validate(value, { length }) {
		return value.length <= length;
	},
	params: ["length"],
	message: (fieldName, placeholders) => {
		return `The ${fieldName} field must have ${placeholders.length} characters at most.`;
	},
});

extend("phoneNumber", {
	validate(value) {
		let pattern = /^\+[1][ ]\(([2-9]{1}[0-9]{2})\)[ ][0-9]{3}[-][0-9]{4}$/;
		return pattern.test(value);
	},
	message: (fieldName, placeholders) => {
		return `The ${fieldName} field is not a valid phone.`;
	},
});

// extend("isNo", (value) => {
// 	return value.toLowerCase() === "no";
// });

extend("isHomeOwner", {
	validate(value) {
		return value.toLowerCase() === "yes";
	},
	message: (fieldName, placeholders) => {
		return "Sorry! You must be a homeowner to qualify for this program.";
	},
});
